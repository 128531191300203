.comments {
    overflow-y: auto;
    word-break: break-word;
    padding: 0 2rem;
}

.single.comments {
    padding: 0px;
}

.comments li {
    margin-bottom: 0.5rem;
    line-height: 1.2;
}