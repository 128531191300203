.login {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    gap: 2rem;
}

.login::before {
    display: block;
    content: '';
    background: url('../../Assets/login.jpg') no-repeat center center;
    background-size: cover;
}

.forms {
    max-width: 30rem;
    padding: 1rem;
    margin-top: 20vh;
}

@media (max-width: 40rem) {
    .login {
        grid-template-columns: 1fr;
    }

    .login::before {
        display: none;
    }

    .forms {
        max-width: 100%;
    }
}