.footer {
    background: #fb1;
    padding: 3rem 1rem 0 1rem;
    height: 10rem;
    text-align: center;
    color: #764701;
}

.footer p {
    margin-top: 1rem;
}